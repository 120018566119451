import React from 'react';
import { CiPhone } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";

import './styles.scss';
import {useDevice} from "../../hooks/useDimensions";
import {tel, telBeauty} from "../../utils/constants";

function Contacts() {
  const { isMobile } = useDevice();

  return (
    <div className="contacts">
      <h1>Контакты:</h1>
      <h1>Теперь у нас 3 точки!</h1>

      <div className="contacts__title">Около ТРЦ</div>
      <iframe
        title="Яндекс-карта"
        src="https://yandex.ru/map-widget/v1/?from=mapframe&indoorLevel=1&ll=42.188109%2C47.512799&mode=poi&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D176813215776&source=mapframe&utm_source=mapframe&z=17.95"
        width={isMobile ? 350 : 700} frameBorder="0"></iframe>

      <div className="contacts__title">На В-6 на Гагарина рядом с Яндекс.Маркетом</div>
      <iframe
        src="https://yandex.ru/map-widget/v1/org/lya_rulet/196898784366/?ll=42.225849%2C47.522782&z=17"
        width={isMobile ? 350 : 700} frameBorder="0"></iframe>

      <div className="contacts__title">На Черникова, 19, рядом со Сбербанком</div>
      <iframe
        src="https://yandex.ru/map-widget/v1/org/lya_rulet/52084232841/?from=mapframe&ll=42.197672%2C47.522975&source=mapframe&utm_source=mapframe&z=18"
        width={isMobile ? 350 : 700} frameBorder="0"></iframe>

      <div className="contacts__content">
        <div className="contacts__section">
          <div className="contacts__section__icon"><CiClock2 size={24}/></div>
          <div>
            <div className="contacts__section__title">Время работы</div>
            <div className="contacts__section__description">С 8:00 до 22:00</div>
          </div>
        </div>
        <div className="contacts__section">
          <div className="contacts__section__icon"><CiPhone size={24}/></div>
          <div>
            <div className="contacts__section__title">Номер телефона</div>
            <div className="contacts__section__description">
              <a href={`tel://${tel}`}>{telBeauty}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="contacts__advice">По вопросам сотрудничества и заказа продукции для ресторанов звоните по номеру
        выше
      </div>
    </div>
  );
}

export default Contacts;
