import React from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Navigation } from 'swiper/modules';

import KeyProduct, {KeyProductProps} from "../KeyProduct";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './styles.scss';
const breadData: KeyProductProps[] = [
  {
    title: 'Круассаны Ля Рулет',
    description: 'От классического фисташкового и миндального до уникального с курицей-карри и даже том-яма! Каждый круассан создан, чтобы удивлять и радовать наших дорогих посетителей.',
    price: '139₽ - 249₽',
    buttonText: 'Забронировать стол',
    image: 'croissants.jpg',
  },
  {
    title: 'Авторские кофейные напитки',
    description: 'Откройте для себя уникальные сочетания, такие как клубника с бананом, малина с нотками кокоса, и изысканный белый шоколад с базиликом. Или же погрузитесь в сладость миндального печенья и нежность сливочной лаванды.',
    price: '270₽',
    buttonText: 'Забронировать стол',
    image: 'menu/coffee.png',
  },
  {
    title: 'Хрустящий Тартин',
    description: 'Откройте для себя тартин - хрустящий снаружи и нежный внутри. Идеальный выбор для любителей традиционного хлеба с современным шиком.',
    price: '150₽',
    buttonText: 'Забронировать стол',
    image: 'menu/tartin.jpg',
  }
];


function KeyProducts() {
  return (
    <div className="key-products">
      <div className="key-products__title">
          Наши знаковые продукты
      </div>

      <Swiper
        spaceBetween={0}
        effect={'fade'}
        navigation={true}
        loop={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {breadData.map((v) => (
          <SwiperSlide>
            <KeyProduct key={v.title} title={v.title} description={v.description} price={v.price} buttonText={v.buttonText} image={v.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default KeyProducts;
