import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useParams, useNavigate } from 'react-router-dom';
import cn from "classnames";
import { MENU } from '../../utils/menu';
import './styles.scss';

function Menu() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки изображений
  const { category } = useParams(); // Извлекаем параметр категории из URL
  const navigate = useNavigate();

  useEffect(() => {
    if (category) {
      const categoryMatch = MENU.find(item => item.category.toLowerCase() === category.toLowerCase());
      if (categoryMatch && !categoryMatch.isDisabled) {
        setSelectedCategory(categoryMatch.category);
      } else {
        navigate("/menu"); // Если категория не найдена, перенаправляем на общий список
      }
    } else {
      setSelectedCategory(MENU[0].category); // По умолчанию выбираем первую категорию
    }
  }, [category, navigate]);

  const handleCategorySelect = (category: string) => {
    navigate(`/menu/${category.toLowerCase()}`);
  };

  const handleProductSelect = (id: number) => {
    navigate(`/menu/product/${id}`);
  };

  const handleImageLoad = () => {
    console.log('LOAD')
    setLoading(false); // Сброс состояния загрузки при успешной загрузке изображения
  };

  const selectedProducts = MENU.find(
    item => item.category.toLowerCase() === selectedCategory.toLowerCase()
  )?.products ?? [];

  useEffect(() => {
    setLoading(true); // Установка состояния загрузки при изменении категории
    setTimeout(() => setLoading(false), 4000);
  }, [selectedCategory]);

  return (
    <div className="menu">
      <h1>Меню Ля Рулет</h1>

      <div className="menu__navbar">
        {MENU.filter((v) => !v.isDisabled).map((item, index) => (
          <button
            key={index}
            className={cn(
              "menu__navbar__item",
              {'active': selectedCategory.toLowerCase() === item.category.toLowerCase()}
            )}
            onClick={() => handleCategorySelect(item.category)}
          >
            {item.categoryTitle}
          </button>
        ))}
      </div>

      <TransitionGroup className="menu__list">
        {selectedProducts.filter((v) => !v.isDisabled).map((product, index) => {
          const mainImage = (Array.isArray(product.imgName) ? product.imgName?.[0] : product.imgName) ?? 'no-image.jpg';

          return (
            <CSSTransition key={index} timeout={200} classNames="fade">
              <div className={cn("menu__list__card", {"menu__list__card--no-image": !product.imgName || !product.description})} onClick={() => handleProductSelect(product.id)}>
                {loading && <div className="loader"></div>} {/* Показываем лоадер, пока изображение не загрузится */}
                <img
                  src={`/images/menu/${mainImage}`}
                  alt={product.name}
                  onLoad={handleImageLoad} // Устанавливаем обработчик события onLoad
                  onError={handleImageLoad} // Устанавливаем обработчик события onError на случай ошибки загрузки
                  style={{ opacity: loading ? '0' : '1' }} // Скрываем изображение, пока оно загружается
                />
                {product.imgName && product.description && (
                  <div className="description-overlay">
                    <p>{product.description}</p>
                  </div>
                )}
                <h3>{product.name}</h3>
                <p>{Array.isArray(product.price) ? product.price.map(p => `${p.size}: ${p.price}`).join(', ') : product.price} ₽</p>
              </div>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </div>
  );
}

export default Menu;
