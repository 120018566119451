import React from 'react';
import './styles.scss';
import {tel} from "../../../../utils/constants";

const Welcome = () => {
  const handleCall = () => {
    window.open(`tel:${tel}`, "_blank");
  };

  const handleVisit = () => {
    window.open("https://www.instagram.com/la_rouletterie/", "_blank");
  };

  return (
    <div className="welcome-container">
      <div className="welcome-header">
        Добро пожаловать в Ля Рулет
      </div>
      <div className="welcome-subtext">
        Откройте для себя наш мир изумительных десертов и круассанов, лучшего кофе и вкуснейшего хлеба.
      </div>
      <div className="welcome-buttons">
        <button className="button-default primary" onClick={handleCall}>
          Позвонить
        </button>
        <button className="button-default secondary" onClick={handleVisit}>
          Социальные сети
        </button>
      </div>
    </div>
  );
};

export default Welcome;
